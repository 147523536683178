import { CheckCircleIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import React, { useState } from "react";
import round from "lodash/round";

import Header from "../header";
import PlanSlider from "../plan-slider";

const includedFeatures = [
  "Unlimited team standups",
  "Full schedule customization",
  "Custom report questions",
  "Unlimited report history",
  "View participant activity",
  "Support multiple timezones",
  "Reporting statistics",
  "Export reporting data",
];

const faqs = [
  {
    id: 1,
    question: "What is a participant?",
    answer:
      "A participant is any Slack user that is part of a standup. If your team is 50, but only 10 are involved in standups, you would only require 10 participants.",
  },
  {
    id: 1,
    question: "Is pricing per user or total?",
    answer:
      "Pricing listed is always the total cost. In order to simplify the billing process for your team, we include tiers that allow participants up to the listed quota. For example, the $30/mo tier would include up to 30 pariticpants at a value of $1.00 per participant!",
  },
  {
    id: 2,
    question: "Is a credit card required for the trial?",
    answer:
      "Nope! You can add the Slack bot and start running standups with all features for 30 days, without requiring a credit card.",
  },
  {
    id: 3,
    question: "Can I upgrade if my required participants number grows?",
    answer:
      "Of course! You can manage your billing any time and changes to the plan will take effect immediately.",
  },
  {
    id: 4,
    question: "What happens if I cancel my plan?",
    answer:
      "You can cancel your plan any time and all standups will remain active until the end of your current billing cycle.",
  },
];

const buttonStyles = {
  active:
    "relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-purple-500 focus:z-10 sm:w-auto sm:px-8",
  normal:
    "relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-purple-500 focus:z-10 sm:w-auto sm:px-8",
};

const planMap = {
  0: {
    count: 5,
    monthly: 10,
    yearly: 100,
  },
  1: {
    count: 10,
    monthly: 15,
    yearly: 150,
  },
  2: {
    count: 20,
    monthly: 25,
    yearly: 250,
  },
  3: {
    count: 30,
    monthly: 30,
    yearly: 300,
  },
  4: {
    count: 40,
    monthly: 39,
    yearly: 390,
  },
  5: {
    count: 50,
    monthly: 45,
    yearly: 450,
  },
  6: {
    count: 9999,
    monthly: 70,
    yearly: 700,
  },
};

const getTotalParticipants = (tier) => {
  if (tier === 6) {
    return "Unlimited";
  }

  return planMap[tier].count;
};

const getPrice = (tier, isYearly) => {
  const price = planMap[tier];

  return isYearly ? price.yearly : price.monthly;
};

const getParticipantCost = (tier, isYearly) => {
  const price = isYearly
    ? getPrice(tier, isYearly) / 12
    : getPrice(tier, isYearly);
  const participants = getTotalParticipants(tier);
  return round(price / participants, 2).toFixed(2);
};

const PricingContent = () => {
  const [monthly, setMonthly] = useState(true);
  const [tier, setTier] = useState(0);

  return (
    <div className="bg-white pt-6">
      <Header />

      <div className="bg-white">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center">
              <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
                Simple, Transparent Pricing
              </h1>
              <p className="mt-5 text-xl text-gray-500 sm:text-center">
                Each plan includes the same core features, simply pick based on
                how many participants you need
              </p>
              <div className="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
                <button
                  type="button"
                  className={
                    monthly ? buttonStyles.active : buttonStyles.normal
                  }
                  onClick={() => setMonthly(true)}
                >
                  Monthly billing
                </button>
                <button
                  type="button"
                  className={`ml-0.5 ${
                    monthly ? buttonStyles.normal : buttonStyles.active
                  }`}
                  onClick={() => setMonthly(false)}
                >
                  Yearly billing
                </button>
              </div>
              <div className="text-center mt-2">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                  Get 2 months free on yearly billing!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 bg-indigo-500 pb-10">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-white" />
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                  <div className="md:flex">
                    <div className="w-full md:w-3/4">
                      <PlanSlider
                        tier={tier}
                        setTier={setTier}
                        count={getTotalParticipants(tier)}
                      />
                    </div>
                    <div className="w-full md:w-1/4 md:pl-6 pb-6">
                      <div className="py-4 bg-gray-100 h-full rounded-lg flex flex-col items-center justify-center">
                        <p class="flex items-baseline text-gray-900">
                          <span class="text-5xl font-extrabold tracking-tight">
                            ${getPrice(tier, !monthly)}
                          </span>
                          <span class="ml-1 text-xl font-semibold">
                            /{monthly ? "month" : "year"}
                          </span>
                        </p>
                        {tier < 6 && (
                          <span className="block text-sm text-green-600">
                            ($
                            {getParticipantCost(tier, !monthly)} / participant)
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                        Every plan includes
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul className="mt-8 space-y-5 md:grid md:grid-cols-2  lg:space-y-0 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-5">
                      {includedFeatures.map((feature) => (
                        <li
                          key={feature}
                          className="flex items-start md:col-span-1"
                        >
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-indigo-500 pb-6">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="space-y-4">
              <h2 className="text-3xl font-extrabold text-white">
                Frequently asked questions
              </h2>
              <p className="text-lg text-indigo-200">
                Can’t find the answer you’re looking for? Reach out to our{" "}
                <Link to="/contact" className="font-medium text-white">
                  customer support
                </Link>{" "}
                team.
              </p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="text-lg leading-6 font-medium text-white">
                      {faq.question}
                    </dt>
                    <dd className="mt-2 text-base text-indigo-200">
                      {faq.answer}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingContent;
