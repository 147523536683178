import React from "react";

import CallToAction from "../components/call-to-action";
import Pricing from "../components/pricing";
import Footer from "../components/footer";
import Metadata from "../components/metadata";

const PricingPage = () => {
  return (
    <>
      <Metadata title="Pricing - StandupWizard" />
      <Pricing />
      <CallToAction />
      <Footer />
    </>
  );
};

export default PricingPage;
