import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import ReactSlider from "react-slider";

const PlanSlider = ({ count, tier, setTier }) => {
  return (
    <div className="mb-4">
      <div className="mb-7">
        <div class="text-sm text-gray-500 uppercase">Included Participants</div>
        <div class="text-3xl font-extrabold">{count}</div>
      </div>
      <ReactSlider
        min={0}
        max={6}
        className="pricing-slider"
        trackClassName="pricing-slider__track"
        thumbClassName="pricing-slider__thumb"
        markClassName="pricing-slider__mark"
        renderThumb={(props) => (
          <div {...props}>
            <div className="pricing-slider__inner-thumb">
              <ChevronLeftIcon className="pricing-slider__left-icon w-6 h-6 inline-block" />
              <ChevronRightIcon className="pricing-slider__right-icon w-6 h-6 inline-block" />
            </div>
          </div>
        )}
        renderMark={(props) => (
          <div {...props}>
            <span className="w-6 h-6 rounded-full bg-black" />
          </div>
        )}
        marks={[0, 1, 2, 3, 4, 5, 6]}
        value={tier}
        onChange={(val) => setTier(val)}
      />
    </div>
  );
};

export default PlanSlider;
